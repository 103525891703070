import BaseGridPage from "../../../Base/BaseGridPage";
import React from "react";

const keyName = 'ZmuPostsAdminPage';
export default class ZmuPostsAdminPage extends BaseGridPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.title=`список охотпользователей`;
        context.gridName = "ZmuPostsGrid";
        context.tableName = 'zmu_posts';
        context.subElementName = 'ZmuPostsPage';
        context.topMenuButtonNames = ['reload', 'xls', 'print', 'add', 'clipboard'];
        context.filters = [{name: 'year'}];
    }

}