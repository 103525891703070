import ErrorBoundary from "../../ErrorBoundary";
import BaseGridPage from "../../Base/BaseGridPage";
import * as f from "../../common/Funcs";
import BaseEditPage from "../../Base/BaseEditPage";

//отчеты в разделе сведения о животном мире
const keyName = 'ZmuPostsPage';
export default class ZmuPostsPage extends BaseEditPage {
    static defaultProps = {
        height: '200px',
        width: '500px'
    }
    constructor(props) {
        super(props);
        const context = this;
        context.title=`список должностей`;
        context.tableName = 'zmu_posts';
        context.idName = 'post_name';
        context.titleName = 'post_name';
        context.rowId = context.props.rowId || document.getCookie(context.tableName);
        context.columnsCount = 1;
        context.fieldsSet = [
            {title: '', pos: 0},
        ];
        context.filters=[];
        context.hiddenColumns = [];
        context.topMenuButtonNames= ['reload'];
        context.columnsDefaults = [...context.lastUserColumns, ...[
            {column_name: 'post_name', hidden:false, pos: 0, row: 0},
        ]];
        context.subTables = [];
        context.state = {
            data: null, columns: [], title: '', rights: props.rights
        };
    }
    isEditRight() {
         return  f.isAdmin();
    }
}

