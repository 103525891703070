import React, {Component} from "react";
import * as f from "../../../common/Funcs";
import BaseTopPage from "../../../Base/BaseTopPage";

const Ext = window['Ext'];
const keyName = `ZmuIndexAdminPage`;
const title = `Зимний маршрутный учет. Настройка видов года.`;
export default class ZmuIndexAdminPage extends BaseTopPage {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const now=new Date();
        context.filters =[{
            name: 'year',
            required: true,
            message: f.locale('needyear')
        }];
        context.state = {
            content: [`<p></p>`]
            , title: title
        };
    }

}