import React from 'react';
import { Panel} from "@sencha/ext-react-modern";
import SimpleMap from "./SimpleMap";
import * as f from "../common/Funcs";

let context = null;
const keyName = "SimpleEditMap";
export default class SimpleEditMap extends SimpleMap {
    static defaultProps = {
        // mapButtonNames: ['upload', 'download', 'clear'],
        mapButtonNames: ['upload', 'download', 'edit','clear'],
    }

    constructor(props) {
        super(props);
        context = this;
        const readonly = props.readonly;
        context.state.legendOn = false;
        context.topMenuButtons = [];
    }

    isEditRight(context) {
        return !context.props.readonly;
    }

    addEditButton(buttons) {
        const context = this;
        if (!context.isEditRight(context)) return;
        let text;
        if (context.geomType == 'Point')
            text = (context.props.record?.geom || (context.state.features && context.state.features[0])) ? 'Переместить точку' : 'Поставить точку';
        if (context.geomType == 'LineString')
            text = (context.props.record?.geom || (context.state.features && context.state.features[0])) ? 'Изменить' : 'Нарисовать';
        context.topMenuButtons[1]=({
            iconCls: 'x-fa fa-edit',
            html: `<label class="menu-label">${text||'изменить'}</label>`,
            handler: () => context.editTool(context),
            hidden: !(context.isEditRight(context))
        })
        // buttons[1] = (<EditButton key={'editButton'} pressed={(this.state.inEdit)} func={() => this.editTool()}/>);
    }

    addClearButton(buttons) {
        const context = this;
        if (!context.isEditRight(context) ||
            !(context.props.record?.geom ||
                (context.state.features && context.state.features[0]))
        ) return;
        context.topMenuButtons[2]=({
            iconCls: 'x-fa fa-trash',
            html: `<label class="menu-label">${f.locale('clear_map')}</label>`,
            handler: () => context.clearTool(context),
            hidden: !(context.props.record.geom || (context.state.features && context.state.features[0]))
        })
        // buttons[1] = (<EditButton key={'editButton'} pressed={(this.state.inEdit)} func={() => this.editTool()}/>);
    }

    addDropButton(buttons) {
        const context = this;
        if (!context.isEditRight(context) ||
            !(context.props.record?.geom ||
                (context.state.features && context.state.features[0]))
        ) return;
        context.topMenuButtons[2]=({
            iconCls: 'x-fa fa-trash',
            html: `<label class="menu-label">${f.locale('clear_map')}</label>`,
            handler: () => context.dropTool(context),
            hidden: !(context.props.record.geom || (context.state.features && context.state.features[0]))
        })
        // buttons[1] = (<EditButton key={'editButton'} pressed={(this.state.inEdit)} func={() => this.editTool()}/>);
    }

    addUploadButton(buttons) {
        const context = this;
        if (!context.isEditRight(context)||context.geomType == 'Point') return;
        const fileElement = document.createElement('input');
        fileElement.type = 'file';
        fileElement.id = 'map_file';
        fileElement.className = 'upload-file-input hidden';
        fileElement.addEventListener("change", (field) => {
            context.uploadTool(field, context)
        });
        document.body.appendChild(fileElement);
        //по клику - клик на инпуте
        //на change input-a  запрос на загрузку файла и загрузить если да
        context.topMenuButtons[3]=({
            iconCls: 'x-fa fa-upload',
            html: `<label class="menu-label">${f.locale('path_upload')}</label>`,
            tooltip: f.locale('path_upload'),
            handler: () => {
                fileElement.click();
            }
        })
    }

    addDownloadButton(buttons) {
        const context = this;
        if (context.geomType == 'Point'||
            !(context.props.record?.geom ||
                (context.state.features && context.state.features[0]))
        ) return;
        context.topMenuButtons[4]=({
            iconCls: 'x-fa fa-download',
            html: `<label class="menu-label">${f.locale('shp')}</label>`,
            tooltip: f.locale('shp'),
            handler: () => {
                context.downloadTool('shp',context);
            }
        })
        context.topMenuButtons[5]=({
            iconCls: 'x-fa fa-download',
            html: `<label class="menu-label">${f.locale('gpx')}</label>`,
            tooltip: f.locale('gpx'),
            handler: () => {
                context.downloadTool('gpx',context);
            }
        })
    }


    render() {
        const context = this;
        const height = context.props.height;
        const width = context.props.width;
        const readonly = context.props.readonly;
        let toolBox = '';
        // if (!readonly) {
        // const buttons = context.state.buttons;
        const buttons = context.addButtons();
        toolBox = <Panel layout={'vbox'} margin={3} key={`toolBoxPanel${keyName}`} className={'toolbox-panel'}
                         name={`toolBoxPanel${keyName}`}>
            {buttons}
        </Panel>;
        // }
        return <Panel
            layout={'vbox'} margin="3"
            key={`mapPanel${keyName}`}
            name={`mapPanel${keyName}`}
            ref={p => context.cmp = p}
        >
            <Panel
                region={"top"} margin="3"
                layout={'fit'}
                height={'25px'}
                cls={'edit-window-panel'}
                key={'simpleMapPanel'}
                tools={context.topMenuButtons}
                ref={p => { if(p) context.mapCmp = p.cmp;
                }}/>
            <Panel>
                <div className={`map-${keyName}`}
                     style={{height: height, width: width}}
                     ref={(m) => context.mapDiv = m}/>
            </Panel>
        </Panel>;
    }

}
