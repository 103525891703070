import ErrorBoundary from "../../ErrorBoundary";
import BaseEditPage from "../../Base/BaseEditPage";
import * as f from "../../common/Funcs";
import {Component} from "react";
import {Button, ComboBoxField, ExtTextareafield, FormPanel, Textfield, Toolbar} from "@sencha/ext-react-modern";
import DialogForm from "../../common/DialogForm";
import EditFormField from "../../collections/EditFormField";
import ReactDOM from "react-dom";


export default class ZmuContactPage extends Component {
    constructor(props) {
        super(props);
        const context = this;
        context.elements = {};
        if (window.IasConfig.devMode) console.debug("ZmuContactPage constructor", arguments);
    }

    sendContact() {
        const context = this;
        const formdata = new FormData();
        let fio='';
        ['family_name', 'first_name', 'second_name'].forEach((key) => {
            const value=context.form.cmp.getValues()[key];
            formdata.append(key, context.form.cmp.getValues()[key]);
            fio+=value+' ';
        });
        formdata.append('contact_active', true);
        formdata.append('id', '-1');
        debugger;
        const submit = (result) => {
            f.callRelationSend({
                props: {
                    relation: {
                        "tableName": "huntusers_contacts",
                        "parentField": "contact_huntuser",
                        "relField": "huntuser_contact",
                        rowId: context.props.route_huntuser,
                        "relValue": [result.contact_id],
                        "replace": false
                    },
                    idName: 'contact_id',
                    rowId: context.props.route_huntuser,
                },
                callback: (res) => {
                    const params = {
                        context: context,
                        tableName: 'huntusers_contacts',
                        id: res.data.row_id,
                        data: {post_in: context.form.cmp.getValues().post_in, row_id: res.data.row_id},
                        callback: () => {
                            context.props.apply(result.contact_id, `${fio.substring(0, fio.length - 1)}, ${context.form.cmp.getValues().post_in}`);
                            context.elements.zmuContactDialog.dialog.cmp.hide();
                        }
                    };
                    f.setObjectData(params);
                },
                items: [{data: result.data}], replace: false
            });
        };
        f.setObjectData({
            context: context,
            tableName: 'contacts',
            id: 'null',
            values: formdata,
            callback: submit
        })
    }

    render() {
        const context = this;
        context.fields = ['family_name', 'first_name', 'second_name'].map(
            (key, i) => <Textfield key={key} id={key} name={key} label={f.locale(key)} value={context.props.values[i]}
                                   ref={fi => fi && (context.elements[key] = fi)}
                                   required/>);
        const comboName = 'post_in';
        context.fields.push(<ComboBoxField
            key={comboName} name={comboName} required={true} forceSelection={true}
            store={context.props.posts}
            displayField="value"
            valueField="value"
            label='Должность'
            ref={fi => fi && (context.elements[comboName] = fi)}
        />);
        const form = <FormPanel method={"POST"} key={"zmuContactForm"} id={"zmuContactForm"} width={'800px'}
                                ref={fi => fi && (context.form = fi)}>
            {context.fields}
            <Toolbar shadow={false} docked="bottom" layout={{type: 'hbox', pack: 'right'}}>
                <Button text="Сохранить" handler={() => {
                    context.sendContact();
                }}/>
                <Button text="Отмена" handler={() => context.elements.zmuContactDialog.dialog.cmp.hide()}/>
            </Toolbar>
        </FormPanel>;
        context.dialog = <DialogForm showEditPanel={true} editFormEl={form} propName={'zmuContactDialog'}
                                   ref={fi => fi && (context.elements['zmuContactDialog'] = fi)}
                                   title="Новый контакт ЗМУ"
                                   key={'zmuContactDialog'} parent={context}/>
        // window.dialogs.push(dialog);
        return [context.dialog];
    }
}


