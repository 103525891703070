import React from 'react';
import * as g from "../../common/gisFuncs";
import * as f from "../../common/Funcs";
import {ExtRownumberer, Panel} from "@sencha/ext-react-modern";
import {transform as Transform} from 'ol/proj';
import SimpleEditMap from "../../Maps/SimpleEditMap";
import {BaseTextButton} from "../../Base/BaseButtons";
import ExtGrid from "@sencha/ext-react-modern/dist/ExtGrid";
import ExtGridcolumn from "@sencha/ext-react-modern/dist/ExtGridcolumn";
import * as Controls from "../MapControls";
import landstyle from "../styles/landspol";
import admstyle from "../styles/admpol";
import zmustyle from "../styles/zmu";
import GridField from "../../collections/GridField";
import Point from "ol/geom/Point";

const Ext = window['Ext'];

let context = null;
const keyName = "ZmuRouteMap";
export default class ZmuRouteMap extends SimpleEditMap {
    static defaultProps = {
        mapButtonNames: ['upload', 'download',
            'edit', 'vertex',
            //    , 'clear'
        ],
        layerNames: [
            {
                layerName: 'landspol', hidden: false, type: 'own', styleFunction: landstyle
            },
            {
                layerName: 'admpol', hidden: false, type: 'own', styleFunction: admstyle
            },
            {
                layerName: 'routeslin', hidden: false, type: 'own', styleFunction: zmustyle
            },
        ],
        load: 'auto',
        width:  '100%',
    }

    constructor(props) {
        super(props);
        context = this;
        context.geomType = context.props.geomType || 'LineString';
        context.columnNames = ['xd', 'yd'];
        context.columnText = {'xd': 'ºвд', 'xm': "'вд", 'xs': "''вд", 'yd': 'ºсш', 'ym': "'сш", 'ys': "''сш"};
        context.style = 1;
        context.state = {
            features: [],
            coordinates: [],
            columns: context.getColumns(context),
            legendOn: false,
            vertexVisible: false,
            currentTopo: 'eeco'
        };
        context.store = Ext.create('Ext.data.Store', {data: []});
        context.parent = context.props.parent.props.parent;
    }

    componentDidMount() {
        super.componentDidMount();
        const context = this;
        context.setColumnNames(context);
        context.fillStore(context);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);
        console.log(`features`, context.state.features);
        if (prevState.columns.length != context.state.columns.length || prevState.vertexVisible != context.state.vertexVisible) {
            return context.fillStore(context);
        }
        if (prevProps.rowId != context.props.rowId) {
            context.coords = [];
            context.fillStore(context);
        }
        const routeFeature = context.state.features?.find?.(f => f.values_.type == 'route' || f.values_.type == 'plan');
        const previousRouteFeature = prevProps.features?.find?.(f => f.values_.type == 'route' || f.values_.type == 'plan');
        if ((previousRouteFeature && JSON.stringify(previousRouteFeature.getGeometry().getCoordinates())) != (routeFeature && JSON.stringify(routeFeature.getGeometry().getCoordinates()))) {
            context.coords = routeFeature.getGeometry().getCoordinates().map(c => Transform(c, 'EPSG:3857', 'EPSG:4326'));
            context.fillStore(context);
        }
        return true;
    }

    getAdditControls() {
        return [
            Controls.getLayersToggle(),
            Controls.getAreaMeasure(),
            Controls.getLineMeasure(),
            Controls.getPdfTool(),
            Controls.getInfo(),
            Controls.getClearSelection()];
    }

    addVertexButton(buttons) {
        if (!buttons) buttons = [];
        const context = this;
        context.topMenuButtons[6] = ({
            iconCls: '',
            html: `<label class="menu-label">${'Подписи поворотов'}</label>`,
            handler: () => context.setState({vertexVisible: !context.state.vertexVisible})
        })
        return buttons;
    }

    setColumnNames(context) {
        switch (context.style) {
            case 1:
                context.columnNames = ['xd', 'yd'];
                break;
            case 2:
                context.columnNames = ['xd', 'xm', 'yd', 'ym'];
                break;
            case 3:
                context.columnNames = ['xd', 'xm', 'xs', 'yd', 'ym', 'ys'];
        }

    }

    addEmptyRow(context) {
        let cc = {};
        context.columnNames.map(c => {
            cc[c] = null;
        });
        context.store.loadRawData(cc, true);
    }

    fillStore(context) {
        const cds = [];
        const style = context.style;
        context.store.clearData();
        if (context.coords) {
            context.coords.map(c => {
                let cc = {};
                //две координаты в цикле
                for (let i = 0; i < 2; i++) {
                    cc[context.columnNames[i * style]] = Number(c[i]).toFixed(6);
                }
                //остаток от деления записываем в следующий регистр
                //две координаты в цикле
                //pos - заполняемая позиция в регистре 2 - минуты 3 секунды
                const calc = (cAr, pos = 2, k = 60) => {
                    for (let i = 0; i < 2; i++) {
                        const bi = i * style;
                        const remainder = cAr[context.columnNames[bi + pos - 2]] % 1;
                        cAr[context.columnNames[bi + pos - 2]] = Number((cAr[context.columnNames[bi + pos - 2]] - remainder).toFixed(6));
                        cAr[context.columnNames[bi + pos - 1]] = Number((remainder * k).toFixed(6));
                    }
                    return cAr;
                }
                for (let pos = 2; pos < style + 1; pos++) {
                    cc = calc(cc, pos);
                }
                cds.push(cc);
            });
            context.store.fields = [...context.columnNames.map(c => {
                return {name: c}
            }),
                {name: 'del'}];
            if (context.state.vertexVisible)
                context.addFeatures({
                    geoJson: {
                        type: 'FeatureCollection',
                        features: context.state.features[0].getGeometry().getCoordinates().map((c, i) => {
                            return {
                                type: 'Feature',
                                geometry: {
                                    type: 'Point',
                                    coordinates: c
                                },
                                properties: {
                                    num: (i + 1).toString(),
                                    source: 'vertex',
                                    type: 'vertex'
                                }
                            }
                        })
                    },
                    layerName: 'important', context, apply: true, styleFunction: zmustyle
                });
            else {
                context.importantSource.removeFeatures(context.importantSource.getFeatures().filter(f => f.values_.type == 'vertex'));
                context.olMap.render();
            }
            context.store.loadRawData(cds);
        }
    }

    getColumns(context) {
        const editable = (!(context.parent && context.parent.isApproved() && context.parent.isEditRight()));
        const n = context.style;

        const w = Math.floor(90 / (context.style * 3));
        const columns = context.columnNames.map((c, i) => <ExtGridcolumn
            dataIndex={c} key={c}
            text={context.columnText[c]}
            flex={((i + 1) == n || (i + 1) == n * 2) ? {grow: 2} : null}
            maxWidth={`${(300 * 0.5 / n).toFixed(0)}px`}
            width={((i + 1) == n || (i + 1) == n * 2) ? 'auto' : `${w}%`}
            editable={editable}/>);
        columns.push(<GridField key={'del'} column_name={'del'}
                                column_label={'Удалить'}
                                description={{}}
                                grid={context.grid}
                                align={'center'}
                                ignoreExport={true}
                                editable={false}
                                data_type={'custom'}
                                ignorePrint={true}
                                renderer={({record}) => {
                                    return <div className="x-fa fa-trash"
                                                onClick={() => {
                                                    context.store.remove(record);
                                                }}/>
                                }}/>);
        if (context.grid) {
            context.grid.cmp.setColumns([...context.columnNames.map(c => {
                return {dataIndex: c, width: `${w}%`, text: context.columnText[c], editable: !context.props.readonly}
            }), context.grid.cmp.getColumns().at(-1)]);
        }
        return columns;
    }

    isEditRight(context) {
        return !context.parent.isApproved() && context.parent.isEditRight();
    }

//очистить привязку
    clearTool() {
        const context = this;
        const p = context.props.parent || context.props.rowId;
        f.confirm(`Удалить геометрию объекта?`, (confirm) => {
            if (confirm) {
                const callback = (result) => {
                    if (result.state) {
                        context.olMap.mapControl.featuresSource.clear();
                        context.olMap.mapControl.selectSource.clear();
                    }
                }
                g.deleteGeomById({context, id: p.rowId, tableName: p.tableName, idName: p.idName, callback});
            }
        });

    }

//запись в поле
    setToField(feature) {
        if (!context.geomField) return;
        context.props.parent.gisFeature = feature;
        const coords = feature.getGeometry().getCoordinates();
        let object = context.geomField.cmp.getValue();
        if (object) object = JSON.parse(object);
        else object = {
            type: feature.getGeometry().getType(),
            crs: {"type": "name", "properties": {"name": "urn:ogc:def:crs:EPSG:3857"}}
        };
        object.coordinates = coords;
        return JSON.stringify(object);
    }

    getValues(file) {
        let result = new FormData();
        result.append('file', file);
        return result;
    }

    applyTable(context) {
        const data = context.store.getData();
        let coords = [];
        let k = 1;
        data.items.map(item => {
            if (item.getData()['xd'] && !isNaN(item.getData()['xd']) && item.getData()['yd'] && !isNaN(item.getData()['yd'])) {
                const coord = [0, 0];
                let k = 1;
                for (let pos = 0; pos < context.style; pos++) {
                    for (let i = 0; i < 2; i++) {
                        coord[i] += item.getData()[context.columnNames[i * context.style + pos]] / k;
                    }
                    k *= 60;
                }
                ;
                coords.push(coord);
            }
        });
        const feature = context.state.features.find(f => f.values_.type == 'route' || f.values_.type == 'plan');
        const props = (feature) ? feature.getProperties() : {};
        delete props.geometry;
        coords = coords.map(c => Transform(c, 'EPSG:4326', 'EPSG:3857'));
        const geoJson = [{
            type: 'Feature',
            geometry: {
                type: 'LineString',
                crs: {type: "name", properties: {name: "EPSG:3857"}},
                coordinates: coords
            },
            properties: props
        }];
        context.clearFeatures({context, layerName: 'important'});
        context.clearFeatures({context, layerName: 'features'});
        context.addFeatures({
            geoJson: {
                type: 'FeatureCollection',
                features: geoJson.filter(f => f.properties.important)
            }, layerName: 'important', context, styleFunction: zmustyle
        });
        const gisFeature = context.addFeatures({
            geoJson: {
                type: 'FeatureCollection',
                features: geoJson.filter(f => f.properties.type == 'route' || f.properties.type == 'plan')
            }, context
        });
        //  const gisFeature = context.addFeatures({geoJson, context: context}).filter(f=>!f.getProperties().important);
        context.setState({features: gisFeature});
        //   context.select({features: gisFeature, context: context});
        context.gotoFeatures({features: gisFeature, context: context});
        context.mapResize();
    }

    getGrid(context, columns) {
        const editable = (!context.parent.isApproved() && context.parent.isEditRight());
        if (!columns) columns = context.getColumns(context);
        // const plugins = {
        //     gridfilters: true,
        //     gridexporter: true,
        // };
        const func = (style) => {
            for (let i = 1; i < 4; i++) {
                context[`b${i}`].cmp.removeCls('active');
            }
            context[`b${style}`].cmp.setCls('active');
            context.style = style;
            context.setColumnNames(context);
            context.setState({columns: context.getColumns(context), columnNames: context.columnNames});
        }
        return <Panel key={'gp'} layout={'vbox'}>
            <Panel key={'add'} layout={'hbox'}>
                {(editable) ?
                    [<BaseTextButton key={'addrow'} text={'Добавить строку'}
                                     func={() => context.addEmptyRow(context)}/>,
                        <BaseTextButton text={'Применить к карте'} key={'applytomap'}
                                        func={() => context.applyTable(context)}/>] : ''}
            </Panel>
            <Panel key={'coords'} layout={'hbox'}>
                <Panel key={'bc'}>
                    <div style={{marginRight: '10px'}}>Формат:</div>
                </Panel>
                <BaseTextButton key={'gbut'} text={'X,xx°'}
                                ref={(b) => (b) ? context.b1 = b : null}
                                func={() => func(1)}/>
                <BaseTextButton key={'mbut'} text={'X° Y,yy′'}
                                ref={(b) => (b) ? context.b2 = b : null}
                                func={() => func(2)}/>
                <BaseTextButton key={'sbut'} text={'X° Y′ Z,zz″'}
                                ref={(b) => (b) ? context.b3 = b : null}
                                func={() => func(3)}/>
            </Panel>
            <ExtGrid
                layout={'fit'}
                key={keyName + 'grid'}
                name={keyName}
                height={context.getHeight() * 2 - 200}
                // rowHeight={context.props.rowHeight}
                variableHeights={false}
                sortable={false}
                rowNumbers={(context.props.noRN) ? null :
                    {width: 50}}
                // plugins={plugins}
                plugins={(editable) ? ['cellediting'] : []}
                // platformConfig={{
                //     '!desktop': {
                //         plugins: {
                //             grideditable: editable,
                //         }
                //     },
                //     desktop: {
                //         plugins: {
                //             gridcellediting: editable,
                //         }
                //     }
                // }}
                store={context.store}
                ref={g => {
                    context.grid = g;
                }}
                listeners={{
                    columnmenucreated(grid, column, menu, eOpts) {
                        menu.on('beforeshow', (sender) => {
                            sender.items.items.map(i => {
                                f.localeMenu(i);
                            });
                        });
                    }
                }}
            >
                {columns}
            </ExtGrid>

        </Panel>
    }

    render() {
        const context = this;
        const grid = context.getGrid(context, context.state.columns);
        let toolBox = '';
        // if (editable) {

        const buttons = context.addButtons(context.state.features);
        if (context.mapDiv) {
            context.mapDiv.style.height=context.props.height+'px';
        }
        // toolBox = <Panel layout={'vbox'} margin={3}
        // key={`toolBoxPanel${keyName}`} name={`toolBoxPanel${keyName}`}>
        //     {buttons}
        // </Panel>;
        // }
        toolBox = <Panel
            region={"top"} margin="3"
            layout={'fit'}
            height={'25px'}
            cls={'edit-window-panel'}
            key={`toolBoxPanel${keyName}`}
            tools={context.topMenuButtons}
            ref={p => {
            }}/>;
        return <Panel
            layout={'hbox'} margin="3"
            key={`mapPanel${keyName}`}
            name={`mapPanel${keyName}`}
            ref={p => context.cmp = p}
        >
            <Panel bodyCls={`map-${keyName}`}
                   key={'mapcont'}
                   width={'100%'}
                   height={context.props.height-50}
                   ref={(m) => {
                       if (m?.cmp)
                           context.mapCmp = m.cmp;
                   }}
                   listeners={
                       {
                           resize: (element, info, eOpts) => {
                               g.mapResize(context);
                           }
                       }
                   }
            >
                {toolBox}
            </Panel>
            <Panel
                key={'pan3'}
                collapsed={true}
                resizable={{
                    split: true,
                    edges: 'west',
                    dynamic: true
                }}
                docked={'right'}
                height={'100%'}
                width={'30%'}
            >
                {grid}
            </Panel>
        </Panel>;
    }
}
