import React, {Component} from 'react';
import {
    Panel,
    FormPanel,
    FieldSet,
    Toolbar,
} from '@sencha/ext-react-modern';
import * as f from "../common/Funcs";
import Subs from "../collections/subElements"
import EditFormField from '../collections/EditFormField';
import BasePage from "./BasePage";
import {
    SaveButton, SaveCloseButton, ChildButton, CloseButton, CopyButton, PrintButton, DeleteButton, ReloadButton
} from "../common/Buttons";
import * as html2canvas from "html2canvas";
import * as g from "../common/gisFuncs";
import ErrorBoundary from "../ErrorBoundary";

const keyName = 'BaseEditPage';

export default class BaseEditPage extends BasePage {
    static defaultProps = {
        height: '100%',
        width: '98%'
    }

    constructor(props) {
        super(props);
        const context = this;
        //высота и ширина ограничения для конкретной формы
        //разворачиваем по умолчанию на весь экран
        context.maximizeOnShow = false;
        //столбцов по умолчанию 2
        context.columnsCount = 2;
        if (window.IasConfig.devMode) console.debug("BaseEditPage constructor", arguments);
        //поле-ключ из приходящих данных
        context.idName = 'row_id';
        //поле из таблицы вызова
        context.titleName = 'full_name';
        //набор значений, будет объединен при загрузке структуры. объединяется по полю "column_name"

        context.columnsDefaults = [];
        context.lastUserColumns = [
            {
                column_name: 'last_user', is_list: true, disabled: true, cls: 'last-class',
                row: 1000, pos: 1, hidden: () => {
                    return context.state.data?.last_user == null;
                },
                source: {
                    tableName: 'users',
                    titleField: 'fio',
                    idField: 'user_id'
                }
            },
            {
                column_name: 'last_modified',
                cls: 'last-class',
                width: 250,
                data_type: 'date',
                disabled: true,
                hidden: () => context.state.data?.last_modified == null,
                row: 1000,
                pos: 0
            }
        ];
        context.subTables = [
        ];
        context.elements = {};
        context.footerButtonNames = ['saveclose', 'save', 'close'];
        //меню страницы
        context.topMenuButtonNames = ['print', 'reload'];
        //можно задать поле подписи, как в таблице
        context.rowId = (props.rowId) ? props.rowId :
            (props.parent) ? props.parent.rowId : document.getCookie(context.tableName);
        if (!context.rowId) context.rowId = -1;
        //набор групп, вставляется по порядку {title,placeholder}
        context.fieldsSet = [
            {title: 'Основная информация'}];
        context.state = {
            data: null, columns: [], title: '', rights: props.rights
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const context = this;
        if (!context.state.form&&context.form) {
            context.setState({form: context.form});
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const context = this;
        if (nextState.rights != context.state.rights || typeof (context.isEdit) == 'undefined')
            context.isEdit = f.isEditRight(nextProps.rights || nextState.rights);
        //    if (nextState.record==context.state.record) {return false;}

        return true;
    }

    addReloadButton(i) {
        const context = this;
        const key = 'reloadbutton';
        context.topMenuButtons.push(<ReloadButton text={'Обновить'} key={key} func={() => {
            if (context.getData) context.getData({context});
            if (context.grid) context.grid.getData({context:context.grid});
        }}/>)
    }

    /*печатная форма*/
    getJson() {
        const context = this;
        //разобрать строки на колонки и данные
        const getCD = (context) => {
            const state = context.state;
            const source = Array.isArray(state.data) ? state.data : [state.data];
            const data = [];
            const columns = [];
            //строки
            state.columns.filter(c => (c.column_name != context.idName &&
                c.column_name != context.props.idName
                && (!(context.props.hiddenColumns) || context.props.hiddenColumns.filter(h => h == c.column_name).length == 0)
                && c.column_name.indexOf('rights') == -1
                && c.column_name.indexOf('upload') == -1)
            ).map(c => {
                columns.push({
                    column_name: c.column_name,
                    column_label: c.column_label || f.locale(c.column_name),
                    data_type: c.data_type,
                    cls: c.className
                    // hidden:c.hidden
                });
                //данные
                source.map((r, idx) => {
                    if (!data[idx]) data[idx] = {};
                    data[idx][c.column_name] =
                        (c.is_list) ? context.elements[c.column_name].field.cmp.getRawValue() :
                            (c.data_type == 'date') ? (new Date(r[c.column_name])).toLocaleDateString() : r[c.column_name];
                })
            });
            return {
                columns: columns,
                data: data
            };
        }
        //верхний уровень
        // const result=getCD(context);
        const result = context.getColData({context});
        result.desc = {title: context.title, view_name: context.tableName};
        result.subs = [];
        //подтаблицы
        const subNames = Object.keys(context.elements).filter(k => context.elements[k].isSub);
        subNames.map(s => {
                const element = context.elements[s];
                if (element.grid) {
                    // const res = getCD(element.grid);
                    const res = context.getColData({
                        context: element.grid, scolumns: element.grid.state.columns,
                        source: Array.isArray(element.grid.state.data) ? element.grid.state.data : [element.grid.state.data]
                    });
                    res.desc = {title: element.props.title, view_name: s};
                    result.subs.push(res);
                }
            }
        )
        return result;

    }

    /*печатная форма, получение картинки, если есть на странице*/
    async getImg(context) {
        const promise = new Promise((resolve) => {
            if (context.olMap) {
                html2canvas(context.olMap.olMap.getViewport(), g.getExportOptions()).then(function (canvas) {
                    //   document.body.appendChild(canvas);
                    const imgData = canvas.toDataURL('image/png');
                    return resolve(imgData);
                });

            } else return resolve(null);
        });
        const result = await promise;
        return result;
    }

    filesPath() {
        return '';
    }

    setParentDialog(context, dialog) {
        context.parentDialog = dialog;
    }

    innerPanelHeight(isNew = false) {
        const context = this;
        const base = (context.baseHeight) ? context.baseHeight : (f.getCentralHeight());
        const result = base -
            ((context.isEdit) ? (window.IasConfig.headerHeight * 2) :
                (window.IasConfig.headerHeight) - ((isNew) ? 0 : context.fieldsSet.length * 25));
        return result;
    }

    /*заголовок формы редактирования по умолчанию*/
    titleFunc(context, record, parentRow) {
        if (record)
            context.title = (context.titleName) ? record[context.titleName] : 'не задано поле заголовка';
        else context.title =
            f.locale(`new${context.tableName}`);
        return context.title;
    }

    //функция запроса данных с сервера.
    // Если repeat - вызываем пересчет. Только для ZMU
    getData(params) {
        const {context, callback, defaults, repeat = false} = params;
        if (window.IasConfig.devMode) console.debug(window.funcName());
        const prnt = context.props.parent;
        context.defaults = defaults;
        const tableName = context.tableName || prnt.tableName;
        // if (tableName == '-') return;
        const rowId = context.rowId;
        if (window.IasConfig.devMode) console.debug(`${keyName} getData ${tableName} ${rowId}`);
        const _params = {
            context: context, tableName: tableName, id: rowId, callback: (result) => {
                context.isNew = (result.data[result.id_field] == null);
                result.columns = result.columns.map(c => {
                    const def = context.columnsDefaults.filter(d => d.column_name == c.column_name)[0];
                    if (context.idName == c.column_name) c.hidden = true;
                    //если есть значения по умолчанию, связь с родителем
                    const ext = (context.props.pageDefaults) ? context.props.pageDefaults.filter(d => d.column_name == c.column_name)[0] : {};
                    c = {...c, ...def, ...ext};
                    if (context.isNew) {
                        result.data[c.column_name] = c.value;
                        if (c.defaultValue) {
                            result.data[c.column_name] = (typeof (c.defaultValue) == 'function') ? c.defaultValue() : c.defaultValue;
                        }
                    }
                    return c;
                });
                context.columnsDefaults.filter(d => !result.columns.some(c => c.column_name == d.column_name)).map(d => result.columns.push(d));
                //!!!!проблема выбора прав, проверять на гибридных правах
                if (context.props.rights && !result.rights) result.rights = context.props.rights;
                if (context.upResult) result = context.upResult(context, result);
                context.setState({
                        data: result.data,
                        columns: result.columns,
                        id_field: result.id_field,
                        rights: result.rights
                    },
                    () => {
                        try {
                            g.mapResize(context);
                            // context['pnl0'].cmp.expand();
                            context.state.columns.filter(c => c.is_list && c.source).map(c => {
                                    (c.record = result.data);
                                    if (context.elements[c.column_name].getRefData)
                                        context.elements[c.column_name].getRefData(context.elements[c.column_name]);
                                }
                            );
                        } catch (e) {
                            debugger;
                        }
                    });
                if (context.parentDialog) {
                    context.parentDialog.centerEl(context.parentDialog);
                    if (context.form?.cmp)
                        context.form.cmp.validate();
                    if (context.maximizeOnShow) {
                        context.parentDialog.cmp.cmp.maximize();
                    }
                }
                //         if (parent.dialog) parent.dialog.cmp.setTitle(result.data[context.titleName]);
            }
        };
        const prs = {...context.defaults, ..._params};
        if (callback) prs.callback = callback;
        if (tableName) {
            context.setState({data: null, columns: []}, function () {
                if (context.isUser) f.getUserData(prs);
                else f.getObjectData(prs);
            })
        }

    }

    //закрыть редактирование
    onCancel(context) {
        window.unmask();
        context.props.parent.hideEditPanel(context.props.parent);
    }

    //сохранить и закрыть редактирование
    onOk(context) {
        context.onSave({context, close: true});
    }

    //базовая функция для сбора данных для отправки на сервер
    getBaseValues(context) {
        if (window.IasConfig.devMode) console.debug(window.funcName(), context.form.cmp.getValues());
        let result = new FormData();
        Object.keys(context.elements).map(k => {
            const cmp = context.elements[k]?.field?.cmp;
            if (cmp&&!cmp.readonly) {
                let value = cmp.getValue();
                if (value != null)
                    switch (cmp.xtype) {
                        case "checkboxfield":
                            value = cmp.isChecked();
                            break;
                        case "datepickerfield":
                            value = value.toLocaleDateString();
                            break;
                        case "timefield":
                            value = value.toLocaleTimeString();
                            break;
                    }
                if (!f.exist(value)) {
                    const col = context.state.columns.find(c => c.column_name == k);
                    if (col)
                        value = col.defaultValue;
                    else
                        value = '';
                }
                if (!f.exist(value)) value = null;
                result.append(k, value);
            }
        })
        Object.keys(context.elements).filter(k => {
            return context.elements[k]?.file;
        }).map(k => result.append(k, context.elements[k].file.file));
        return result;
    }

    //данные из формы для отправки на сервер
    getValues(context) {
        if (window.IasConfig.devMode) console.debug(`${keyName} getValues`, context.form.cmp.getValues());
        const result = context.getBaseValues(context);
        return result;
    }

    //сохранить редакцию
    onSave(params) {
        let {context, close, isCopy, path, setDataFunc, callback} = params;
        if (window.IasConfig.devMode) console.debug(window.funcName());
        if (!setDataFunc) setDataFunc = context.setData;
        //обнулить id если копия
        if (isCopy) {
            context.rowId = null;
            context.elements[context.idName].field.cmp.setValue(null);
        }
        const form = context.form;
        const parent = context.props.parent;
        const valid = (form) ? form.cmp.validate() : true;
        Object.keys(context.elements).map(k => {
            if (context.elements[k]?.grid?.single && !context.elements[k]?.props?.disableSingleEdit) {
                context.elements[k].grid.single.onSave({context: context.elements[k].grid.single});
            }
        })
        if (valid) {
            const submit = (result) => {
                const closeFunction = () => {
                    // if (result.state) return context.onCancel(context);
                    try {
                        form.cmp.getFields()[context.idName].setValue(result.data[context.idName]);
                    } catch {
                    }
                    if (parent.update) parent.update(parent);
                    else if (parent.grid) parent.grid.getData({context: parent.grid});
                    let newId;
                    if (result?.data)
                        newId = result?.data[context.idName] || result?.data[context.idField];
                    if (newId) {
                        context.rowId = newId;
                        if (parent.dialog) parent.dialog.cmp.setTitle(result.data[context.titleName]);
                        context.getData({context});
                    }
                    if (callback) callback(result);
                    if (close) context.onCancel(context);
                };

                if (result.flash) {
                    f.alert({
                        title: 'Ответ сервера',
                        message: `Изменения не сохранены. Сервер вернул ошибку: ${JSON.stringify(result.flash)}`
                    });
                } else {
                    if (context.props.relation && !(context.rowId)) {
                        f.callRelationSend({
                            props: {
                                relation: context.props.relation,
                                idName: context.props.parent.idName,
                                // rowId: context.props.parent.rowId
                                rowId: context.props.parent.rowId || context.props.parent.props.rowId
                            },
                            items: [{data: result.data}], replace: false
                        })
                    }
                    ;
                    if (f.exist(result.state))
                        f.toast({
                            title: 'Ответ сервера',
                            message: result.message || 'Изменения сохранены.',
                            timeout: window.IasConfig.messageTimeout
                        }, () => context.forceUpdate());
                    else
                        f.toast({
                            title: 'Ответ сервера',
                            message: 'Изменения сохранены.',
                            timeout: window.IasConfig.messageTimeout
                        }, closeFunction);
                }
            };
            setDataFunc(context, submit, path);
        } else {
            f.toast({
                message: `Не все поля заполнены корректно.`, timeout: window.IasConfig.messageTimeout * 3
            });
        }
    }

    deleteCallback(context, result) {
        const parent = context.props.parent;
        if (parent.update) {
            parent.update(parent);
        } else {
            const grid = context.props.parent.grid;
            if (result && grid) grid.getData({context: grid});
        }
        context.onCancel(context);
    }

    //удалить запись
    deleteRecord(context, path) {
        const dropFunc = (tableName, id) => {
            const params = {
                context: context.props.appViewPort, id: id, tableName: tableName,
                callback:
                    (result) => {
                        context.deleteCallback(context, result)
                    }
            };
            if (path) params.path = path;
            f.dropObject(
                params)
        };
        if (!context.props.id || !context.tableName)
            f.confirm({
                title: 'Удаление',
                message: `Вы действительно хотите удалить запись?`,
                context
            }, (e) => {
                if (e)
                    dropFunc(context.tableName, context.rowId);
            });
    }

    //выделить ребенка
    recordChild(context) {
        const id = context.rowId;
        if (window.IasConfig.devMode) console.debug(`${keyName} fromIdEditPanel`, id);
        document.setCookie(context.props.tableName, null);
        // context.props.parent.rowId = null;
        //задаем предварительные значения для полей
        //context.props.parent.setState({pageDefaults: [{column_name: 'parent_id', value: id, disabled: true}]});
        //    context.props.parent.showEditPanel(context.props.parent);
        //}
        context.props.parent.grid.recordChild(context.props.parent.grid);
    }

    //скопировать запись
    copyRecord(context) {
        context.onSave({context, isCopy: true});
    }

    //отправить на сервер
    setData(context, submit, path) {
        f.setObjectData({
            context: context,
            tableName: context.tableName,
            id: (context.rowId),
            path: path,
            //связь через таблицу связей
            relation: context.props.relation,
            // rowId: context.props.parent.rowId,
            rowId: context.props.parent.rowId,
            values: context.getValues(context),
            callback: submit
        })
    }

    getSubType(context, fsg) {
        return {el: Subs[fsg.elementName || `BaseSubGridPanel`]};
    }

    //суб таблица
    getSubElement(context, fsg, maxHeight) {
        const en = context.getSubType(context, fsg);
        let readonly = false;
        readonly = readonly || f.getBool(fsg.readonly) || f.getBool(fsg.disabled);
        const e = <en.el
            title={fsg.title}
            key={fsg.elementName}
            name={fsg.elementName}
            block={fsg.block}
            editWidth={fsg.editWidth}
            height={fsg.height}
            parent={context}
            form={context.state.form}
            appViewPort={context.props.appViewPort}
            tableName={fsg.tableName}
            disableSingleEdit={fsg.disableSingleEdit}
            testName={fsg.testName}
            idName={fsg.idName}
            noSingle={fsg.noSingle}
            showAll={fsg.showAll}
            collapsed={fsg.collapsed}
            tpl={fsg.tpl}
            olMap={context.olMap}
            rowHeight={fsg.rowHeight}
            rowId={context.rowId}
            // rowId={context.rowId}
            topMenuButtonNames={fsg.topMenuButtonNames}
            hiddenColumns={fsg.hiddenColumns}
            columnsDefaults={fsg.columnsDefaults}
            relColumnsDefaults={fsg.relColumnsDefaults}
            singleColumns={fsg.singleColumns}
            activeColumn={fsg.activeColumn}
            subElementName={fsg.subElementName}
            subElementParams={fsg.subElementParams}
            relation={fsg.relation}
            filterString={fsg.filterString}
            geomType={fsg.geomType}
            rights={context.props.rights || context.state.rights}
            record={context.state.data}
            readonly={readonly}
            ref={(el => {
                context.elements[fsg.subName || fsg.elementName] = el;
                if (el) el.isSub = true;
            })}
        />;
        return <ErrorBoundary key={`err${fsg.tableName || fsg.elementName}`}
                              height={fsg.height}
                              column={fsg.column} block={fsg.block}
        >{e}</ErrorBoundary>;
    }

    //поле формы
    getEditField(context, fsc, isNew) {
        //проверяем наличие группы и добавляем если надо
        // if (!context.isEdit) fsc.readonly = true;
        // const value = (isNew) ? (fsc.value || fsc.defaultValue) : context.state.data[fsc.column_name];
        const value = context.state.data[fsc.column_name];
        const columndesc = fsc;
        Object.keys(columndesc).map(k => {
            if (typeof (columndesc[k]) == 'function' && k.substring(0, 2) != 'on' && k.substring(2, 1) != k.substring(2, 1).toUpperCase()) {
                columndesc[k] = columndesc[k](context);
            }
        })
        try {
            const e = <EditFormField
                columnDescription={columndesc} key={fsc.column_name} record={context.state.data} isNew={isNew}
                ref={(el => {
                    context.elements[fsc.column_name] = el;
                })}
                value={(columndesc.data_type == 'path') ? ((value) ? context.filesPath(context) + value : null) : value}
                tableName={context.tableName} parent={context} dialog={context.props.parent.dialog}
                disabled={!(context.isEditRight() && (!columndesc.disabled))}
                readonly={!(context.isEditRight() && (!columndesc.readonly))}
            />;
            //    readonly={!context.isEdit || columndesc.readonly}/>;
            return e;
        } catch (error) {
            return null;
        }
    }

    //сформировать панель аккардиона
    getSubPanel(context, fselements, idx, title, isNew, column) {
        const frows = [[]];
        fselements.map(el => {
                const record = (el.props.columnDescription && el.props.columnDescription.row) ? el.props.columnDescription.row : 0;
                if (!record || record == 0) frows[0].push(el);
                else {
                    if (!frows[record])
                        frows[record] = [];
                    frows[record].push(el);
                }
            }
        );
        const rows = frows.map((record, idx) => {
            return <FieldSet layout={{type: 'fit', align: 'left'}} key={`record${idx}`}>{record}</FieldSet>
        });
        return <Panel
            title={title} key={`fieldSet${idx}`}
            cls={'edit-window-panel'}
            column={column}
            collapsible={{
                direction: 'top',
                dynamic: true
            }}
            collapsed={context.props.collapsed}
            //   height={'100%'}
            titleCollapse={true}
            hidden={fselements.length == 0} scrollable={true}
            ref={pnl => context['pnl' + idx] = pnl}>
            {rows}</Panel>
    }

    //распределить по столбцам
    getColumns(context, content) {
        const columns = [];
        const length = content.length;
        const count = (length == 1) ? 1 : (Array.isArray(context.columnsCount)) ? context.columnsCount.length : context.columnsCount;
        if (!count) return columns;
        for (let i = 0; i < count; i++) {
            const columnContent = content.filter((c, idx) => {
                    return (c.props.column == i) || ((!f.exist(c.props.column)) && idx >= ((length / count) * (i)) &&
                        (idx < ((length / count) * (i + 1))))
                }
            );
            if (i == count - 1) {
                const buttons = context.getFooterButtons(context.footerButtonNames);
                const toolbar = context.getToolBar(context, buttons);
                columnContent.push(toolbar);
            }
            //ширина колонки
            let width = (100 / count).toFixed(0);
            if (context.columnsCount[i] && context.columnsCount[i].width) width = context.columnsCount[i].width;
            if (i == 0) {
                columns.push(<Panel
                    ref={a => context[`a${i}`] = a}
                    key={`column${i}`}
                    bodyCls={`single-page-column-${i}`}
                    scrollable={(!(context?.columnsCount?.[i]?.noOverflow))}
                    collapsed={false}
                    docked={(count > 1) ? 'left' : false}
                    resizable={(count > 1) ? {
                        split: true,
                        edges: 'east',
                        dynamic: true
                    } : false}
                    listeners={(i == 0 && count > 1) ? {
                        resize: (element, info, eOpts) => {
                            g.mapResize(context);
                        }
                    } : {}}
                    modal={true}
                    width={`${width}%`}
                >
                    {columnContent}
                </Panel>)
            } else {
                columns.push(<Panel
                    ref={a => context[`a${i}`] = a}
                    key={`column${i}`}
                    bodyCls={`single-page-column-${i}`}
                    scrollable={(!(context?.columnsCount?.[i]?.noOverflow))}
                    collapsed={false}
                    modal={true}
                >
                    {columnContent}
                </Panel>)
            }
        }
        return columns;
    }

    //кнопка экспорта в html
    addHtmlButton() {
        const context = this;
        const key = 'printbutton';
        context.topMenuButtons.push(<PrintButton key={key}/>);
    }

    //кнопки в подвале
    getFooterButtons(buttons) {
        const context = this;
        const result = [];

        buttons.map((b, i) => {
            switch (b) {
                case 'save':
                    if (context.isEditRight())
                        result[i] = (<SaveButton key={`${b}Button`} func={() => context.onSave({context})}/>);
                    break;
                case 'saveclose':
                    if (context.isEditRight())
                        result[i] = (<SaveCloseButton key={`${b}Button`} text={`${b}Button`} func={() => {
                            context.onSave({context, close: true});
                        }}/>);
                    break;
                case 'close':
                    result[i] = (<CloseButton key={`${b}Button`} text={`${b}Button`}
                                              func={() => context.onCancel(context)}/>);
                    break;
                case 'delete':
                    if (context.isEditRight() && context.rowId)
                        result[i] = (<DeleteButton key={`${b}Button`} text={`${b}Button`}
                                                   func={() => context.deleteRecord(context)}/>);
                    break;
                case 'copy':
                    if (context.isAddRight() && context.rowId)
                        result[i] = (<CopyButton key={`${b}Button`} text={`${b}Button`}
                                                 func={() => context.copyRecord(context)}/>);
                    break;
                case 'child':
                    if (context.isAddRight() && context.rowId)
                        result[i] = (<ChildButton key={`${b}Button`} text={`${b}Button`}
                                                  func={() => context.recordChild(context)}/>);
                    break;
            }
        });
        return result;
    }

    getToolBar(context, buttons) {
        return <Toolbar key={'editToolBar'} shadow={false} docked="bottom" layout={{type: 'hbox', pack: 'right'}}
                        cls={'no-print'}
                        height={window.IasConfig.headerHeight - 10}>
            {buttons}
        </Toolbar>
    }

    getHeight(block) {
        const context = this;
        let result = 300;
        // if (context.props.block&&context.props.parent?.form?.cmp?.el)
        if (block) {
            if (context.form?.cmp?.el) {
                result = (context.form?.cmp?.el.getHeight() - 120);
            }        // result= (context.props.parent?.form?.cmp?.el.getHeight() - 120);
        }
        return result;
    }

    render() {
        const context = this;
        //признак нового объекта
        const isNew = ((!context.state.data) || context.state.data[context.state.id_field] == null);
        const content = [];
        //заполняем fieldset-ы с сортировкой
        if (!context.state.columns || context.state.columns.length == 0)
            return (<Panel key={'empty'}/>)
        //панели, перебираем, добавляем
        context.fieldsSet.map((fs, idx) => {
            //выбрать поля и отсортировать
            const fscolumns = context.state.columns.filter(c => {
                let result = c.fieldset == fs.fieldset;
                result = result || (!(c.fieldset) && !idx);
                return result;
            }).sort((a, b) => a.pos - b.pos);
            const fselements = [];
            //элементы панели, перебираем, создаем.
            fscolumns.map(fsc => {
                if (fsc.block) fsc.height = context.getHeight(true);
                const e = context.getEditField(context, fsc, isNew);
                if (e) fselements.push(e);
            });
            //подчиненные таблицы
            content[fs.pos || idx] = (context.getSubPanel(context, fselements, idx, fs.title, isNew, fs.column));
        })
        //    подчиненные таблицы
        // if (!isNew && content.length > 0) {
        if (context.props.allowNull || (!isNew && content.length > 0)) {
            //фильтр по имени панели
            const fsgrids = context.subTables.sort((a, b) => a.pos - b.pos);
            fsgrids.map(fsg => {
                if (!f.getBool(fsg.hidden)) {
                    const e = context.getSubElement(context, fsg);
                    if (e) {
                        if (f.exist(fsg.block)) content[fsg.block] = (e);
                        else content.push(e);
                    }
                }
            });
        }
        //кнопки наверху
        const editMenu = context.getEditMenu(context);
        //кнопки внизу
        // const buttons = context.getFooterButtons(context.footerButtonNames);
        // const toolbar = context.getToolBar(context, buttons);
        const columns = context.getColumns(context, content);
        const form = <FormPanel
            key={`${keyName}formEditPanel`}
            cls={'edit-page'} bodyCls={'edit-page-body'}
            ref={f => {
                if (!context.form) {
                    context.form = f;
                    if (f) {
                        f.cmp.validate();
                    }
                }
            }}
            height={'99%'}
            url={`${window.IasConfig.homePath}api/data/${context.tableName}/row/${context.rowId}`}
            layout={'fit'}
        >
            {editMenu}
            {columns}
            {/*{toolbar}*/}
        </FormPanel>

        const panel = <ErrorBoundary key={`${keyName}errbaseeditpanel`}>
            <Panel
                layout={"fit"} margin="3"
                key={`${keyName}baseeditpanel`}
                ref={p => (p) ? (context.cmp = p.cmp) : context.cmp = null}
            >
                {form}
            </Panel></ErrorBoundary>;

        const message = context.props.message || "";
        /*добавить прямой контекст*/
        if (context.props.content) {
            if (Array.isArray(context.props.content))
                context.props.content.map((c) => content.push(c));
            else
                content.push(context.props.content);
        }
        /*получить данные рег. формы*/
        // if (context.props.keyName) content.push(<span key={'keyNamespan'}>{context.props.keyName}</span>)

        /**/
        return (form)
    }
}

