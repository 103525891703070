import * as f from "../../../common/Funcs";
import BasePointPage from "../../../Base/ZMU/BasePointPage";

const keyName = "BirdPage";
export default class BirdPage extends BasePointPage {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.tableName = 'passage_point_bird_view';
        context.columnsDefaults = [...context.columnsDefaults, ...[
            {
                column_name: "spec_id",
                required: true,
                "pos": 2, row: 20,
                "is_list": true,
                source: {
                    tableName: 'zmu_species',
                    titleField: 'spec_name',
                    idField: 'zmu_spec',
                    filterString: `acc_year=${document.getCookie('yearFilter')} and (isbird=true) and disable<>true`,
                }
            },
            {
                "column_name": "distance",
                "pos": 5, row: 30, required: true,
            }]]
    }
}


