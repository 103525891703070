import BasePageGrid from "../../Base/BasePageGrid";
import React from "react";
const keyName='ZmuPostsGrid';
export default class ZmuPostsGrid extends BasePageGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        // context.subElementName = 'ContactPage';
        context.idName = 'post';
        context.pageSize = 300;
        context.paging = false;
        context.columnsDefaults =  [
            {column_name: "post",pos:2, flex:{grow:2}, title: true}
        ];

    }

}