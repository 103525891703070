import BaseGrid from "../Base/BaseGrid";
import AccGrid from "../Grids/Regbook/AccGrid";
import ZmuAdminGrid from "../Grids/ZMU/Acc/ZmuAdminGrid";
import BasePageGrid from "../Base/BasePageGrid";
import BaseSubGrid from "../Base/BaseSubGrid";
import PasTableSubGrid from "../Base/ZMU/PasTableSubGrid";
import HuntUsersGrid from "../Grids/Office/HuntUsersGrid";
import HuntEconsGrid from "../Grids/Office/HuntEconsGrid";
import HuntLandsGrid from "../Grids/Office/HuntLandsGrid";
import DocumentsGrid from "../Grids/Office/DocumentsGrid";
import ContactsSubGrid from "../Grids/Office/ContactsSubGrid";
import NamesakeSubGrid from "../Grids/Office/NamesakeSubGrid";
import ContactsGrid from "../Grids/Office/ContactsGrid";
import BankPropsSubGrid from "../Grids/Office/BankPropsSubGrid";
import HuntDescSubGrid from "../Grids/Office/HuntDescSubGrid";
import HuntCodesSubGrid from "../Grids/Office/HuntCodesSubGrid";
import EconsSubGrid from "../Grids/Office/EconsSubGrid";
import LandsSubGrid from "../Grids/Office/LandsSubGrid";
import LandsWithEcoSubGrid from "../Grids/Office/LandsWithEcoSubGrid";
import CardLogSubGrid from "../Grids/Office/CardLogSubGrid";
import PassLogSubGrid from "../Grids/ZMU/Acc/PassLogSubGrid";
import HuntUserDocumentsSubGrid from "../Grids/Office/HuntUserDocumentsSubGrid";
import LandDocumentsSubGrid from "../Grids/Office/LandDocumentsSubGrid";
import ZoneDocumentsSubGrid from "../Grids/Office/ZoneDocumentsSubGrid";
import StaffSubGrid from "../Grids/Office/StaffSubGrid";
import PickupSubGrid from "../Grids/Office/PickupSubGrid";
import BaseRelationGrid from "../Base/BaseRelationGrid";
import ReportsGrid from "../Grids/Regbook/ReportsGrid";
import UsersAdminGrid from "../Grids/Admin/UsersAdminGrid";
import LogGrid from "../Grids/Admin/LogGrid";
import SessionsGrid from "../Grids/Admin/SessionsGrid";
import NewsGrid from "../Grids/Admin/NewsGrid";
import SpeciesGrid from "../Grids/Regbook/Spec/SpeciesGrid";
import RbSpecSubGrid from "../Grids/Regbook/Spec/RbSpecSubGrid";
import SpecLitSubGrid from "../Grids/Regbook/Spec/SpecLitSubGrid";
import SpecSynSubGrid from "../Grids/Regbook/Spec/SpecSynSubGrid";
import SpecDescSubGrid from "../Grids/Regbook/Spec/SpecDescSubGrid";
import SpecStatusSubGrid from "../Grids/Regbook/Spec/SpecStatusSubGrid";
import BaseByMapGrid from "../Base/BaseByMapGrid";
import MappingsGrid from "../Grids/Regbook/MappingsGrid";
import DtpGrid from "../Grids/Regbook/DtpGrid";
import ViolationsGrid from "../Grids/Regbook/ViolationsGrid";
import TestsSubGrid from "../Grids/ZMU/TestsSubGrid";
import PassCommentSubGrid from "../Grids/ZMU/Acc/PassCommentSubGrid";
import EaObjectsGrid from "../Grids/ZMU/Plan/EaObjectsGrid";
import EaGrid from "../Grids/ZMU/Plan/EaGrid";
import PasAnalizGrid from "../Grids/ZMU/Acc/PasAnalizGrid";
import ZmuGraphGrid from "../Grids/ZMU/Acc/ZmuGraphGrid";
import PasEaAnalizGrid from "../Grids/ZMU/Acc/PasEaAnalizGrid";
import EaAnalizGrid from "../Grids/ZMU/Plan/EaAnalizGrid";
import RAnalizGrid from "../Grids/ZMU/Plan/RAnalizGrid";
import RoutesGrid from "../Grids/ZMU/Plan/RoutesGrid";
import LayoutGrid from "../Grids/ZMU/Plan/LayoutGrid";
import PassagesGrid from "../Grids/ZMU/Acc/PassagesGrid";
import PasTable1SubGrid from "../Grids/ZMU/Acc/PasTable1SubGrid";
import PasTable2SubGrid from "../Grids/ZMU/Acc/PasTable2SubGrid";
import PasTable3SubGrid from "../Grids/ZMU/Acc/PasTable3SubGrid";
import AnimalsGrid from "../Grids/ZMU/Acc/AnimalsGrid";
import BirdsGrid from "../Grids/ZMU/Acc/BirdsGrid";
import EventsGrid from "../Grids/ZMU/Acc/EventsGrid";
import EaRoutesSubGrid from "../Grids/ZMU/Plan/EaRoutesSubGrid";
import EaPassagesSubGrid from "../Grids/ZMU/Acc/EaPassagesSubGrid";
import BiotopsSubGrid from "../Grids/Office/BiotopsSubGrid";
import CorrectGrid from "../Grids/ZMU/Acc/CorrectGrid";
import AccGraphGrid from "../Grids/Regbook/AccGraphGrid";
import ZmuPostsGrid from "../Grids/ZMU/ZmuPostsGrid";
export default {
    BaseGrid,
    AccGrid,
    AccGraphGrid,
    BasePageGrid,
    BaseSubGrid,
    BaseRelationGrid,
    SpeciesGrid,
    HuntUsersGrid,
    HuntEconsGrid,
    HuntLandsGrid,
    DocumentsGrid,
    ContactsGrid,
    ReportsGrid,
    BankPropsSubGrid,
    HuntDescSubGrid,
    HuntCodesSubGrid,
    ContactsSubGrid,
    EconsSubGrid,
    LogGrid,
    SessionsGrid,
    NewsGrid,
    MappingsGrid,
    DtpGrid,
    ViolationsGrid,
    LandsSubGrid,
    LandsWithEcoSubGrid,
    PickupSubGrid,
    StaffSubGrid,
    RbSpecSubGrid,
    UsersAdminGrid,
    BaseByMapGrid,
    HuntUserDocumentsSubGrid,
    LandDocumentsSubGrid,
    ZoneDocumentsSubGrid,
    TestsSubGrid,
    EaObjectsGrid,
    RoutesGrid,
    LayoutGrid,
    EaGrid,
    EaAnalizGrid,
    PasAnalizGrid,
    PasEaAnalizGrid,
    RAnalizGrid,
    PassagesGrid,
    PasTable1SubGrid,
    PasTable2SubGrid,
    PasTable3SubGrid,
    PasTableSubGrid,
    AnimalsGrid,
    BirdsGrid,
    EaRoutesSubGrid,
    EaPassagesSubGrid,
    SpecSynSubGrid,
    SpecLitSubGrid,
    SpecStatusSubGrid,
    SpecDescSubGrid,
    EventsGrid,
    BiotopsSubGrid,
    ZmuAdminGrid,
    CorrectGrid,
    ZmuGraphGrid,
    PassCommentSubGrid,
    CardLogSubGrid,
    PassLogSubGrid,
    NamesakeSubGrid,
    ZmuPostsGrid
};
